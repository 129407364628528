<template>
  <template v-for="item in detailData">
<iframe
title="Inline Frame Example"
width="1000"
height="530"
:src="item">
</iframe>
  </template>
</template>

<script>
export default {
  name: 'vendorCode200',
  props: ['detailData']
}
</script>

<style scoped></style>
<script setup></script>
